import * as api from './api'

//获取亲属联系人列表
export const getContactList = (params) => api.fetchGet('/Enterprise/Employee/FamilyMembers', params)

//添加亲属关系
export const addFamilyMember = (params) => api.fetchPost('/Enterprise/Employee/FamilyMember', params, 2)


//获取亲属联系人详情
export const getContactDetail = (params) => api.fetchGet('/Enterprise/Employee/FamilyMember', params)

//编辑亲属联系人
export const updateContactDetail = (params) => api.fetchPost('/Enterprise/Employee/UpdateFamilyMember', params, 2)

//删除亲属联系人
export const deleteContactDetail = (params) => api.fetchGet('/Enterprise/Employee/DeleteFamilyMember', params, 2)

//切换亲属账号
export const switchContact = (params) => api.fetchGet('/Enterprise/Auth/FamilyMemberToken', params, 2)
