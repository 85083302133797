<template>
  <div class="body">
    <div class="form">
      <div class="form-item">
        <div class="label">姓名</div>
        <input
          v-model="name"
          class="input"
          maxlength="16"
          type="text"
          placeholder="请输入姓名"
        />
      </div>
      <div class="form-item">
        <div class="label">手机号码</div>
        <input
          v-model="phone"
          class="input"
          maxlength="11"
          type="tel"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="form-item">
        <div class="label">身份证</div>
        <input
          v-model="certificateNo"
          class="input"
          maxlength="18"
          placeholder="请输入身份证"
        />
      </div>
      <div class="form-item">
        <div class="label">性别</div>
        <div class="checkbox-group">
          <span
            class="checkbox"
            :class="{ 'checkbox-active': sex === 1 }"
            @click="sex = 1"
            >男</span
          >
          <span
            class="checkbox"
            :class="{ 'checkbox-active': sex === 2 }"
            @click="sex = 2"
            >女</span
          >
        </div>
      </div>
      <div class="form-item">
        <div class="label">年龄</div>
        <div
          class="date-picker"
          :style="{ color: birthday ? '#475266' : '#ccc' }"
          @click="showDatePicker = true"
        >
          <span>{{ birthday || "请选择出生年月" }}</span>
          <van-icon style="margin-left: 10px" name="arrow-down" />
        </div>
      </div>
      <div class="form-item">
        <div class="label">婚姻情况</div>
        <div class="checkbox-group">
          <span
            class="checkbox"
            :class="{ 'checkbox-active': maritalState === 2 }"
            @click="maritalState = 2"
            >未婚</span
          >
          <span
            class="checkbox"
            :class="{ 'checkbox-active': maritalState === 1 }"
            @click="maritalState = 1"
            >已婚</span
          >
        </div>
      </div>
    </div>
    <div class="button-container">
      <van-button
        v-if="id"
        style="margin-right: 10px"
        class="button-default"
        type="primary"
        round
        block
        @click="onDelete"
        >删除家属</van-button
      >
      <van-button
        class="button"
        type="primary"
        round
        block
        @click="onClickSubmit"
        >{{ id ? "更新" : "添加" }}家属</van-button
      >
    </div>

    <van-popup
      v-model="showDatePicker"
      position="bottom"
      round
      safe-area-inset-bottom
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showDatePicker = false"
        @confirm="onConfirmDate"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import { Dialog } from "vant";

import {
  getContactDetail,
  addFamilyMember,
  updateContactDetail,
  deleteContactDetail,
} from "@/api/group.js";

export default {
  data() {
    return {
      showDatePicker: false,
      minDate: new Date(1920, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      name: "",
      phone: "",
      sex: 0,
      maritalState: 0,
      birthday: "",
      certificateNo: "",
      id: void 0,
    };
  },
  created() {
    this.id = Number(this.$route.query.id);
    this.getDetail();
  },
  mounted() {
    const title = this.id ? "编辑家属" : "添加家属";
    this.$setTitle(title);
  },
  methods: {
    //获取详情
    async getDetail() {
      const res = await getContactDetail({
        id: this.id,
      });
      if (!res) return;
      const { birthday, maritalState, name, phone, sex, certificateNo } = res;
      this.name = name;
      this.phone = phone;
      this.sex = sex;
      this.maritalState = maritalState;
      this.certificateNo = certificateNo;
      this.currentDate = new Date(birthday);
      this.birthday = dayjs(birthday).format("YYYY-MM-DD");
    },
    onConfirmDate(value) {
      this.birthday = dayjs(value).format("YYYY-MM-DD");
      this.showDatePicker = false;
    },
    onClickSubmit() {
      if (!this.validate()) return;
      this.onSubmit();
    },
    //提交
    onSubmit: _.debounce(
      async function () {
        const that = this;
        const params = {
          id: this.id,
          name: this.name,
          phone: this.phone,
          sex: this.sex,
          maritalState: this.maritalState,
          birthday: this.birthday,
          certificateNo: this.certificateNo,
        };
        const isEdit = this.id;
        const res = isEdit
          ? await updateContactDetail(params)
          : await addFamilyMember(params);
        if (res.success) {
          Dialog.alert({
            title: `${isEdit ? "编辑" : "添加"}成功！`,
            theme: "round-button",
            confirmButtonColor: "#00c4b3",
            width: 250,
          }).then(() => {
            that.$router.go(-1);
          });
        } else {
          this.$toast(res.message);
        }
      },
      1000,
      { leading: true }
    ),
    validate() {
      if (!this.name) {
        this.$toast("请输入姓名");
        return false;
      } else if (this.phone.length !== 11) {
        this.$toast("请输入正确的手机号码");
        return false;
      } else if (this.certificateNo.length !== 18) {
        this.$toast("请输入正确的身份证号码");
        return false;
      } else if (!this.sex) {
        this.$toast("请选择性别");
        return false;
      } else if (!this.birthday) {
        this.$toast("请选择出生年月");
        return false;
      } else if (!this.maritalState) {
        this.$toast("请选择婚姻情况");
        return false;
      } else {
        return true;
      }
    },
    //删除
    onDelete() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定删除家属吗？",
        confirmButtonColor: "#00c4b3",
        width: 250,
      })
        .then(() => {
          this.deleteAction();
        })
        .catch(() => {
          // on cancel
        });
    },
    //删除操作
    deleteAction: _.debounce(
      async function () {
        const res = await deleteContactDetail({
          id: this.id,
        });
        if (res.success) {
          Dialog.alert({
            title: `删除成功！`,
            theme: "round-button",
            confirmButtonColor: "#00c4b3",
            width: 250,
          }).then(() => {
            this.$router.go(-1);
          });
        } else {
          this.$toast(res.message);
        }
      },
      1000,
      { leading: true }
    ),
  },
};
</script>

<style>
html body {
  background-color: #f4f4f4;
}
</style>

<style scoped>
.body {
  padding-bottom: 50px;
}

.form {
  margin-top: 10px;
  background-color: #fff;
}

.form-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
}

.form-item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #edeff0;
  width: 100%;
  height: 1px;
  transform: scaleY(0.5);
}

.label {
  flex-shrink: 0;
  position: relative;
  font-size: 15px;
  color: #2c2f37;
  font-weight: bold;
}

.label::before {
  content: "*";
  font-size: 15px;
  color: #ff3d00;
}

.input {
  text-align: right;
  font-size: 15px;
  color: #475266;
}

.date-picker {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #cccccc;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 30px;
  background: #f4f4f4;
  border-radius: 50px;
  font-size: 15px;
  color: #475266;
}

.checkbox-active {
  color: #fff;
  background-color: #00c4b3;
}

.checkbox:not(:last-child) {
  margin-right: 10px;
}

.button-container {
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 20px;
  background-color: #fff;
}

.button {
  height: 40px;
}

.button-default {
  height: 40px;
  color: #2c2f37;
  background-color: #f4f4f4;
  border: none;
}

:deep(.van-button__text) {
  font-weight: bold;
}
</style>